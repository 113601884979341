import { useParams } from 'react-router';
import { ServerDataGrid } from '@emburse/embark';

import { PER_PAGE_OPTIONS, USERS_PER_PAGE } from '../../constants';
import { useApi, usePagination } from '../../hooks';
import { listManagedUsers } from '../../api/routes';
import { Link } from 'react-router-dom';
import { ISerializedUserProfile } from '../../types/api/serialized_user_profile';

const managedUserColumns = [
  { field: 'id', hide: true },
  { field: 'companyId', hide: true },
  {
    field: 'user.name',
    headerName: 'Name',
    width: 300,
    shortable: false,
    renderCell: function render(params: any) {
      const userId = params.getValue('user_id') as string;
      const companyId = params.getValue('company_id') as string;
      return <Link to={`/companies/${companyId}/users/${userId}`}>{params.getValue('user')?.name}</Link>;
    }
  },
  {
    field: 'user.email',
    headerName: 'Email Address',
    width: 200,
    sortable: false,
    renderCell: (params: any) => params.getValue('user').email
  },
  { field: 'role', headerName: 'Role', width: 200, sortable: false },
  { field: 'state', headerName: 'State', width: 200, sortable: false }
];

interface PageRouteParams {
  companyId: string;
  userId: string;
}

function UserManagedList() {
  const { companyId, userId } = useParams<PageRouteParams>();

  const { limit, offset, onPageChange } = usePagination(USERS_PER_PAGE);
  const { items, loading } = useApi<ISerializedUserProfile>(listManagedUsers(companyId, userId, limit, offset));

  return (
    <ServerDataGrid
      rowCount={items.count}
      rows={items.rows}
      columns={managedUserColumns}
      autoHeight={true}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
      pageSize={limit}
      loading={loading}
      onPageChange={onPageChange}
    />
  );
}

export { UserManagedList };
