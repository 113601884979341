import { companyRequestBasePath } from '../../api/routes/company_requests';
import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../types/api/serialized_company_request';
import { useState } from 'react';
import { GetAuthorizedApiService, IRequestOptionsWithBody } from '../../api';
import { useError } from '../Common/ErrorHandler';
import { CompanyRequestCreationComponent } from './CompanyRequestCreationComponent';
import { useHistory } from 'react-router-dom';
import { DEFAULT_COUNTRY_CODE } from '../../constants';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';

export function CompanyRequestCreationContainer() {
  const { user } = useAuth0();
  const isSVB = !user.organization_name || user.organization_name.toLowerCase() === 'svb';

  const { showError } = useError();

  const { makePostRequest } = GetAuthorizedApiService();
  const [companyRequest, onCompanyRequestChange] = useState<ISerializedCompanyRequest>({
    address_country_code: DEFAULT_COUNTRY_CODE,
    whitelabel_id: isSVB ? 'SVB' : 'EMBURSE_SPEND'
  } as ISerializedCompanyRequest);
  const navigate = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  // event triggers
  function onChange(value: any, field: ISerializedCompanyRequestKeys, nestedKey?: string) {
    let updatedCompanyRequest;
    const nestedKeyOptions = ['company_field_options', 'request_options'];
    if (nestedKeyOptions.includes(field) && nestedKey) {
      const oldOptions = companyRequest[field] || {} as any;
      updatedCompanyRequest = {
        ...companyRequest,
        [field]: {
          ...oldOptions,
          [nestedKey]: value
        }
      };
    } else {
      updatedCompanyRequest = {
        ...companyRequest,
        [field]: value
      };
    }

    onCompanyRequestChange(updatedCompanyRequest);
  }

  async function createCompanyRequest() {
    try {
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);
      const postOptions: IRequestOptionsWithBody = {
        body: JSON.stringify(companyRequest)
      };
      await makePostRequest(companyRequestBasePath, postOptions);
      toast.success('Created company request!');
      goToCompanyRequestLists();
    } catch (error: any) {
      showError(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  function goToCompanyRequestLists() {
    navigate.push('/company_request');
  }

  return (
    <CompanyRequestCreationComponent
      companyRequest={companyRequest}
      onChange={onChange}
      createCompanyRequest={createCompanyRequest}
      onCancel={goToCompanyRequestLists}
      isSubmitting={isSubmitting}
    />
  );
}
