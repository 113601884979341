import { useParams } from 'react-router';
import { Card, Tabs, TabPanel, Grid, Typography } from '@emburse/embark';

import { getUserForCompany } from '../../api/routes';
import { UserDelegateOfList, UserReceiptsList, UserExpenseTagBucketList } from '.';
import { UnsubmittedExpenseList } from '../Expenses';
import { ISerializedUser } from '../../types/api/serialized_user';
import { displayDateTime, displayYesNo } from '../../helpers/text_formatters';
import { UserExpensesList } from '../Expenses';
import { InfoBoxDataItem } from '../Common/InfoBoxDataItem';
import { UserDelegateList } from '.';
import { useApi, useTabs } from '../../hooks';
import { Loader } from '../Common/UI';
import { UserCreditList } from '../Credits/UserCreditList';
import { UserCardList } from './UserCardList';
import { ISerializedCard } from '../../types/api/serialized_card';
import { UserProfileList } from '../UserProfiles';
import { UserManagedList } from './UserManagedList';

const userTabs = [
  { label: 'User Profiles' },
  { label: 'Expenses', uri: 'expenses' },
  { label: 'Credits', uri: 'credits' },
  { label: 'Cards' },
  { label: 'Unsubmitted Expenses' },
  { label: 'Receipts' },
  { label: 'Delegates' },
  { label: 'Delegate of' },
  { label: 'Custom Fields' },
  { label: 'Managed Users' }
];

function UserInfo() {
  const { companyId, userId } = useParams<{ companyId: string, userId: string }>();
  const { item: user, loading } = useApi<ISerializedUser>(getUserForCompany(companyId, userId));
  const { tabValue, onTabChange } = useTabs(userTabs);

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return <div>no item</div>;
  }

  const {
    name,
    email,
    address_city,
    street_address,
    address_state,
    created_at,
    state,
    currency_code,
    bank_updated_at,
    has_connected_bank,
    personal_cards = [],
    corporate_cards = [],
    alternate_emails
  } = user;

  const cards: ISerializedCard[] = [...personal_cards, ...corporate_cards];
  const secondaryEmails = alternate_emails ? alternate_emails.map((alt) => alt.email).join(', ') : '';

  return (
    <>
      <Typography variant="h4">
        {name}
      </Typography>
      <Card className="card-block">
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item>
            <Typography variant="h6">
              User Information
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="E-mail" value={email}/>
          </Grid>
          {address_city && (
            <Grid item xs={6} md={3}>
              <InfoBoxDataItem title="Address"
                value={`${street_address}, ${address_city}, ${address_state}`}/>
            </Grid>
          )}
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="State" value={state}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="User currency" value={currency_code}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Bank connected" value={displayYesNo(has_connected_bank)}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Bank last connection" value={bank_updated_at ? displayDateTime(bank_updated_at) : '-'}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Joined On" value={displayDateTime(created_at)}/>
          </Grid>
          {secondaryEmails && (
            <Grid item xs={6} md={3}>
              <InfoBoxDataItem title="Secondary emails" value={secondaryEmails}/>
            </Grid>
          )}
        </Grid>
      </Card>
      <Tabs
        id="users-tab-bar"
        tabs={userTabs}
        variant="scrollable"
        initialIndex={tabValue}
        onChange={onTabChange}
      />
      <TabPanel value={tabValue} index={0}>
        <UserProfileList />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <UserExpensesList currency={user.currency_code} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <UserCreditList companyId={companyId} userId={userId} currency={user.currency_code} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <UserCardList cards={cards} />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <UnsubmittedExpenseList currency={user.currency_code} />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <UserReceiptsList companyId={companyId} userId={userId} />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <UserDelegateList />
      </TabPanel>
      <TabPanel value={tabValue} index={7}>
        <UserDelegateOfList />
      </TabPanel>
      <TabPanel value={tabValue} index={8}>
        <UserExpenseTagBucketList companyId={companyId} userId={userId} />
      </TabPanel>
      <TabPanel value={tabValue} index={9}>
        <UserManagedList />
      </TabPanel>
    </>
  );
}

export { UserInfo };
