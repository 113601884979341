export const COMPANY_REQUEST_VALUES = ['invited', 'joined', 'deleted', 'expired', 'errored'] as const;
export type COMPANY_REQUEST_STATE_TYPE = typeof COMPANY_REQUEST_VALUES[number];

export interface ISerializedCompanyRequest {
  id: string;
  created_at: number;
  updated_at: number;
  state: COMPANY_REQUEST_STATE_TYPE;
  company_name: string;
  street_address: string;
  address_city: string;
  address_state: string;
  address_country_code: string;
  postal_code: string;
  partner_unique_id?: string;
  initial_user_first_name: string;
  initial_user_last_name: string;
  initial_user_email_address: string;
  whitelabel_id: string;
  salesforce_id?: string;
  created_company_id?: string;
  request_options: {
    billing_plan?: string;
    emburse_cards?: string;
    implementation?: string;
  };
  company_field_options: {
    website?: string;
  }
}

export type ISerializedCompanyRequestKeys = keyof ISerializedCompanyRequest;
